import React, { useState } from "react";
import {
  Button,
  Drawer,
  Flex,
  Form,
  Input,
  message,
  Modal,
  Space,
  theme,
  Typography,
} from "antd";
import { CloseCircleFilled } from "@ant-design/icons";
import { SendOutlined } from "@ant-design/icons";
import logo from "./images/ninjatō-logo-green-cream.png";
import header from "./images/header-cream.png";
import "./App.css";

const { Text, Title } = Typography;

function App() {
  const [form] = Form.useForm();
  const { token } = theme.useToken();
  const [open, setOpen] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [messageApi, contextHolder] = message.useMessage();

  const success = (response) => {
    messageApi.open({
      type: "success",
      content: "Email sent successfully!",
    });
  };

  const layout = {
    labelCol: {
      span: 5,
    },
    wrapperCol: {
      span: 16,
    },
  };

  const validateMessages = {
    required: "This field is required!",
    types: {
      email: "Not a valid email!",
    },
  };

  const onFinish = async (values) => {
    fetch(
      "https://email-backend-production-48e2.up.railway.app/api/sendmail/",
      {
        method: "POST",
        mode: "no-cors",
        body: JSON.stringify(values),
        headers: {
          "Content-Type": "application/json",
        },
      }
    )
      .then((response) => {
        success();
        form.resetFields();
        setOpenModal(false);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  // const showDrawer = () => {
  //   setOpen(true);
  // };

  const onClose = () => {
    setOpen(false);
  };

  const showModal = () => {
    setOpenModal(true);
  };

  const containerStyle = {
    backgroundColor: "whitesmoke",
    position: "relative",
    marginTop: "0px",
    minHeight: "100vh",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    overflow: "hidden",
    background: token.colorFillAlter,
    border: `1px solid ${token.colorBorderSecondary}`,
    borderRadius: token.borderRadiusLG,
  };

  return (
    <>
      <div style={containerStyle}>
        <img src={logo} style={{ height: "40vmin" }} alt="logo" />
        <p
          className="App-title"
          style={{ fontSize: "50px", marginTop: "-5px" }}
        >
          COMING SOON
        </p>
        <Flex>
          <Space>
            <Button
              type="primary"
              style={{
                backgroundColor: "#4E503d",
                marginTop: "-10px",
              }}
              onClick={() => setOpen(true)}
            >
              <strong>WHO WE ARE</strong>
            </Button>
            <Button
              type="primary"
              style={{
                backgroundColor: "#4E503d",
                marginTop: "-10px",
              }}
              onClick={showModal}
            >
              <strong>GET IN TOUCH</strong>
            </Button>{" "}
          </Space>
        </Flex>
        <p></p>
        <p></p>
        <Drawer
          //title="Ninjatō Specialty Coffees"
          placement="right"
          width="520px"
          closable={false}
          onClose={onClose}
          open={open}
          getContainer={false}
          style={{ backgroundColor: "#eedec5" }}
          // extra={
          //   <Space>
          //     <Button type="primary" onClick={onClose}>
          //       OK
          //     </Button>
          //   </Space>
          // }
        >
          <CloseCircleFilled
            style={{
              fontSize: "26px",
              backgroundColor: "#eedec5",
              color: "#000000",
              marginTop: "-10px",
              marginBottom: "35px",
              borderRadius: 13,
              borderColor: "#eedec5",
            }}
            onClick={onClose}
          />
          <img
            src={header}
            style={{
              width: "472px",
              maxWidth: "90vmin",
              marginTop: "-10px",
              marginBottom: "6px",
              borderRadius: 9,
            }}
            alt="header"
          />
          <Title level={5}>WHO WE ARE</Title>
          <Text>
            Greetings from Salt Lake City, Utah! As a specialty coffee roaster
            nestled in this vibrant community, we take great pride in offering
            top-notch coffee roasts to our valued customers. Our roasting
            technique is carefully crafted to unlock the nuanced flavors
            inherent in each of our specialty coffee beans. We're committed to
            ensuring that every sip you take delivers an unparalleled coffee
            experience.
          </Text>
          <Title level={5}>OUR BEANS</Title>
          <Text>
            Sourced from three farms in southern Minas Gerais, Brazil, our beans
            benefit from distinct temperature fluctuations that enhance their
            natural sweetness. This results in a full-bodied coffee with a
            delightful aroma, caramel notes, subtle chocolate undertones, and a
            delicate citrus acidity, ensuring an exceptional sensory experience.
          </Text>
          <Title level={5}>IMPACT</Title>
          <Text>
            Our coffees adhere to rigorous sustainability standards,
            prioritizing well-being and ethical practices with our growers,
            fostering favorable social conditions.
          </Text>
          {/* <p></p>
          <Flex>
            <Space>
              <Image
                width={150}
                height={200}
                src={roasting_1}
                style={{ borderRadius: "5%" }}
              />
              <Image
                width={150}
                height={200}
                src={label}
                style={{ borderRadius: "5%" }}
              />
              <Image
                width={150}
                height={200}
                src={roasting_3}
                style={{ borderRadius: "5%" }}
              />
            </Space>
          </Flex> */}
        </Drawer>
        {contextHolder}
        <Modal
          //title="Get In Touch"
          centered
          open={openModal}
          onOk={() => setOpenModal(true)}
          onCancel={() => setOpenModal(false)}
          okButtonProps={{ style: { display: "none" } }}
          cancelButtonProps={{ style: { display: "none" } }}
        >
          <Title level={5} style={{ textAlign: "center" }}>
            Get In Touch Via
          </Title>
          <Space direction="vertical">
            <div style={{ marginBottom: "-15px" }}>
              <Text>
                <strong>Phone:</strong> (801) 541-9565
                <p></p>
                <strong>Email:</strong> contact@ninjatocoffee.com
              </Text>
            </div>
          </Space>
          <center>
            <Title level={5}>- OR -</Title>
            <Text>Submit your inquiry here.</Text>
          </center>
          <p></p>
          <Form
            {...layout}
            name="Email Message"
            onFinish={onFinish}
            form={form}
            style={{
              maxWidth: 600,
            }}
            validateMessages={validateMessages}
          >
            <Form.Item
              name="name"
              label="Name"
              rules={[
                {
                  required: true,
                },
              ]}
            >
              <Input />
            </Form.Item>
            <Form.Item
              name="email"
              label="Email"
              rules={[
                {
                  type: "email",
                  required: true,
                },
              ]}
            >
              <Input />
            </Form.Item>
            <Form.Item
              name="subject"
              label="Subject"
              rules={[
                {
                  required: true,
                },
              ]}
            >
              <Input />
            </Form.Item>
            <Form.Item
              name="message"
              label="Message"
              rules={[
                {
                  required: true,
                },
              ]}
            >
              <Input.TextArea />
            </Form.Item>
            <Form.Item
              wrapperCol={{
                ...layout.wrapperCol,
                offset: 16,
              }}
            >
              <Button
                type="primary"
                htmlType="submit"
                style={{
                  color: "#ffffff",
                  backgroundColor: "#4E503d",
                  fontWeight: "bold",
                  marginLeft: "8px",
                }}
              >
                Send <SendOutlined />
              </Button>
            </Form.Item>
          </Form>
        </Modal>
        <h4 style={{ color: "#969696" }}>2024 © Ninjatō Specialty Coffees</h4>
      </div>
    </>
  );
}

export default App;
